import React from "react"
import * as styles from "./index.module.scss"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import { BsBookmarkCheck, BsClock } from "react-icons/bs"
import { AiOutlineHome, AiOutlineShoppingCart } from "react-icons/ai"

const AboutUsPage = () => {
  return (
    <>
      <Seo title="Über uns" />
      <Breadcrumbs title={"Über uns"} />

      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2 col-xl-6 offset-xl-3 text-center mt-5 pt-5 mb-5">
            <img
              alt="Owita Naturals"
              src={require("../../images/logo_mobile.png").default}
              style={{ maxWidth: "255px" }}
            />
            <h2 className={"mt-5"}>Stay fit. Healthy Food.</h2>
            <p>
              Owita naturals ist die ideale Erfüllung für Sie um biologische,
              frische und gesunde Lebensmitteln aus Sri Lanka zu geniessen.
            </p>
          </div>

          <div className="col-sm-6 col-md-4 offset-md-2 text-center px-5">
            <img
              src={require("./farms.png").default}
              alt=""
              style={{ maxWidth: "128px" }}
            />
            <h3>50+</h3>
            <p>Landwirtschaftliche Anbaufläche mit Bio-Gemüse und Obst</p>
          </div>
          <div className="col-sm-6 col-md-4 text-center px-5">
            <img
              src={require("./farmers.png").default}
              alt=""
              style={{ maxWidth: "128px" }}
            />
            <h3>25+</h3>
            <p>Engagierte Bauern, die zertifizierte Bio-Lebensmittel anbauen</p>
          </div>
        </div>
      </div>

      <div className={"line mt-5"}></div>

      <div className="container text-center mt-5">
        <div className="row">
          <div className="col-sm-12">
            <h2>Why us?</h2>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className={styles.round_icon}>
              <BsBookmarkCheck />
            </div>
            <h4>Zertifierte bio Produkte</h4>
            <p>
              Alle angebotenen Produkte werden von zertifizierten Bio-Bauern
              hergestellt.
            </p>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className={styles.round_icon}>
              <AiOutlineHome />
            </div>
            <h4>Heimlieferung</h4>
            <p>Die Pakete werden per Post an jeden Ort in Europa geliefert.</p>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className={styles.round_icon}>
              <AiOutlineShoppingCart />
            </div>
            <h4>Online Bestellung</h4>
            <p>Bestellung via unsere Webseite ist möglich</p>
          </div>

          {/*<div className="col-sm-6 col-lg-3">*/}
          {/*  <div className={styles.round_icon}>*/}
          {/*    <BsClock />*/}
          {/*  </div>*/}
          {/*  <h4>24x7 Open</h4>*/}
          {/*  <p>Last but not least, We are open everyday...</p>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  )
}

export default AboutUsPage
